import { makeAutoObservable } from "mobx";

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  token: string = null;

  userOrganizationId: string = null;

  role: string = "admin";

  setUserOrganizationId = (userOrganizationId: string) => {
    this.userOrganizationId = userOrganizationId;
  };

  setToken = (token: string) => {
    this.token = token;
  };

  setRole = (role: string) => {
    this.role = role;
  };
}

export default UserStore;
