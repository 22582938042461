import { Admin } from "src/types/organization/admins";
import { Badge } from "src/types/organization/badges";
import { Carrier } from "src/types/organization/carriers";
import { Operation } from "src/types/organization/operations";
import { Profile } from "src/types/organization/profiles";
import { Site } from "src/types/organization/sites";
import { PaginatedResponse } from "src/types/responses/PaginatedResponse";
import api from "../clients/api";

class OrganizationApi {
  async getSites(organizationToken: string): Promise<Site[]> {
    const { data } = await api.get<Site[]>(`/organizations/${organizationToken}/sites`);

    return data;
  }

  async getCarriers(
    limit: number,
    offset: number,
    siteToken?: string
  ): Promise<PaginatedResponse<Carrier>> {
    const { data } = await api.get<PaginatedResponse<Carrier>>(`/carriers`, {
      params: { limit, offset, siteToken },
    });

    return data;
  }

  async getCarrier(id: number): Promise<Carrier> {
    const { data } = await api.get<Carrier>(`/carriers/${id}`);

    return data;
  }

  async getAdmins(): Promise<Admin[]> {
    const { data } = await api.get<Admin[]>(`/admins`);

    return data;
  }

  async getSitesProfiles(siteToken: string): Promise<Profile[]> {
    const { data } = await api.get<Profile[]>(`/sites/${siteToken}/profiles`);

    return data;
  }

  async updateSitesDefaultProfiles(siteToken: string, profileIds: number[]): Promise<Profile[]> {
    const { data } = await api.put<Profile[]>(`/sites/${siteToken}/default-profiles`, {
      profileIds,
    });

    return data;
  }

  async getSitesBadges(
    siteToken: string,
    limit: number,
    offset: number
  ): Promise<PaginatedResponse<Badge>> {
    const { data } = await api.get<PaginatedResponse<Badge>>(`/sites/${siteToken}/badges`, {
      params: { limit, offset },
    });

    return data;
  }

  async getCarrierOperations(limit: number, offset: number): Promise<PaginatedResponse<Operation>> {
    const { data } = await api.get<PaginatedResponse<Operation>>(`/carrier-operations`, {
      params: { limit, offset },
    });

    return data;
  }

  async deleteCarrierOperation(jobId: number): Promise<Operation> {
    const { data } = await api.delete<Operation>(`/carrier-operations/${jobId}`);

    return data;
  }

  async assignCarrierProfiles(
    siteToken: string,
    carrierId: string,
    organizationProfilesId: string[] | number[],
    from: string,
    to: string
  ): Promise<Operation> {
    const { data } = await api.patch<any>(`/sites/${siteToken}/carrier-profiles/${carrierId}`, {
      organizationProfilesId,
      from,
      to,
    });

    return data;
  }

  async detachCarrierProfiles(
    siteToken: string,
    carrierId: string,
    carrierProfilesId: string[] | number[]
  ): Promise<Operation> {
    const { data } = await api.delete<any>(`/sites/${siteToken}/carrier-profiles/${carrierId}`, {
      params: { carrierProfilesId },
    });

    return data;
  }

  async batchAssignCarrierProfiles(
    siteToken: string,
    organizationProfilesId: string[] | number[],
    carriersId: string[] | number[],
    from: string,
    to: string
  ): Promise<Operation> {
    const { data } = await api.post<any>(`/sites/${siteToken}/batch-carrier-profiles/assign`, {
      organizationProfilesId,
      carriersId,
      from,
      to,
    });

    return data;
  }

  async batchDetachCarrierProfiles(
    siteToken: string,
    profilesId: string[] | number[],
    carriersId: string[] | number[]
  ): Promise<Operation> {
    const { data } = await api.post<any>(`/sites/${siteToken}/batch-carrier-profiles/detach`, {
      profilesId,
      carriersId,
    });

    return data;
  }

  async assignBadgeToCarrier(carrierId: number, badgeId: number): Promise<Operation> {
    const { data } = await api.post<any>(`/carrier-badges/${carrierId}`, {
      badgeId,
    });

    return data;
  }

  async detachBadgeFromCarrier(carrierId: number, badgeId: number): Promise<Operation> {
    const { data } = await api.delete<any>(`/carrier-badges/${carrierId}/${badgeId}`);

    return data;
  }
}

export const organizationApi = new OrganizationApi();
