export interface User {
  id: string;
  avatar: string;
  email: string;
  name: string;
  [key: string]: any;
}
export enum ROLES {
  ADMIN = "admin",
  INSTALLER = "installer",
}
