import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      common: {
        search: "Search",
        all: "All",
        bulkActions: "Bulk Actions",
        noResultsFound: "No results found",
        page: "Page",
        of: "of",
        success: "Success",
        error: "Error",
        from: "From",
        to: "To",
        noData: "No data",
        alreadyInUse: "Already in use",
        assign: "Assign",
        yes: "Yes",
        no: "No",
        areYouSure: "Are You sure",
        logout: "Logout",
        aboutUs: "About us",
        show: "Show",
        actions: "Actions",
        dragNDrop: "Drag 'n' drop some file here, or click to select file",
        perPage: "Per page:",
        availableSoon: "Available soon",
        button: {
          save: "Save",
          cancel: "Cancel",
          detach: "Detach",
          delete: "Delete",
          upload: "Upload",
        },
        profileVariants: {
          assigning: "Assigning",
          removing: "Removing",
          synchronizing: "Synchronizing",
          scheduled: "Scheduled",
          inserting_connection_error: "Inserting connection error",
          widthdrawing_connection_error: "Widthdrawing connection error",
          removing_error: "Removing error",
          assigning_error: "Assigning error",
          failed: "Failed",
          max_limit_exhausted: "Max limit exhausted",
          assigned: "Assigned",
          queued_to_remove: "Queued to remove",
          queued_to_assign: "Queued to assign",
        },
        badgesVariants: {
          assigned: "Assigned",
          available: "Available",
          blocked: "Blocked",
          error: "Error",
          other: "Other",
          max_limit_exhausted: "Max limit exhausted",
          new: "New",
          queued: "Queued",
          inserting_connection_error: "Inserting connection error",
          widthdrawing_connection_error: "Widthdrawing connection error",
        },
        operationCodesVariants: {
          update_badge_access_control: "Update badge access control",
          synchronize_carrier_with_digital_twin: "Synchronize carrier with digital twin",
          create_carrier_access_control: "Create carrier access control",
          attach_badge_to_carrier: "Attach badge to carrier",
          detach_badge_from_carrier: "Detach badge from carrier",
          synchronize_attached_badge_with_digital_twin:
            "Synchronize attached badge with digital twin",
          synchronize_detached_badge_with_digital_twin:
            "Synchronize detached badge with digital twin",
          attach_carrier_profiles: "Attach carrier profiles",
          detach_carrier_profiles: "Detach carrier profiles",
          synchronize_attached_profiles_with_digital_twin:
            "Synchronize attached profiles with digital twin",
          synchronize_detached_profiles_with_digital_twin:
            "Synchronize detached profiles with digital twin",
          synchronize_new_site_profiles: "Synchronize new site profiles",
        },
      },
      profiles: {
        title: "Profiles",
        tab: "Profiles | SignalOS",
        assignProfiles: "Assign profiles",
        detachCheckedProfiles: "Detach checked profiles",
        thereAreNoProfilesToAssign: "There are no profiles to assign",
        bulkActions: "Bulk Actions",
        assignSelectedProfiles: "Assign selected profiles",
        profilesUpdated: "Profiles updated",
        columns: {
          profileId: "Profile id",
          name: "Name",
          type: "Type",
          accessControlId: "Access control id",
          createdAt: "Created at",
          updatedAt: "Updated at",
          isDefault: "Is default",
          isInUseByCarrier: "Is in use by carrier",
        },
        assignDialog: {
          title: "Assign selected profile to organization",
        },
      },
      badges: {
        title: "Badges",
        tab: "Badges | SignalOS",
        assignBadge: "Assign badge",
        uploadFile: "Upload file with badges",
        createBadge: "Create badge",
        columns: {
          type: "Type",
          createdAt: "Created at",
          updatedAt: "Updated at",
          isInUse: "Is in use",
          accessControls: "Access controls",
          assignedTo: "Assigned to",
        },
        createDialog: {
          title: "Create badge",
          name: "Badge name",
          type: "Type",
        },
        assignBadgeDialog: {
          title: "Assign badge to organization",
        },
        deleteDialog: {
          message: "Do You want to delete badge: ",
          message2: "This cant be undone",
        },
        detachDialog: {
          message: "Do You want to detach badge: ",
        },
        uploadDialog: {
          title: "Upload file with badges (xlsx)",
          download: "Download template",
        },
        toast: {
          badgeCreated: "Badge created",
          badgeDeleted: "Badge deleted",
          badgeAssigned: "Badge assigned",
          badgeDetached: "Badge detached",
          badgeUploaded: "Badge uploaded",
          alreadyAttached: "You can't detach the badge, which is used by organization",
        },
      },
      organizations: {
        title: "Organizations",
        tab: "Organizations | SignalOS",
        columns: {
          name: "Name",
          createdAt: "Created at",
          updatedAt: "Updated at",
          manageOrganization: "Manage organization",
          manageBadges: "Mange badges",
          manageProfiles: "Mange profiles",
        },
        badges: {
          title: "- badges",
          tab: "Organization badges | SignalOS",
          backToOrg: "Back to organizations",
          doYouWantToDetach: "Do You want to detach badge:",
        },
        profiles: {
          title: "- profiles",
          tab: "Organization profiles | SignalOS",
          backToOrg: "Back to organizations",
          assignProfiles: "Assign profiles",
          doYouWantToDetach: "Do You want to detach profile:",
        },
      },
      tenants: {
        title: "Tenants",
        tab: "Tenants | SignalOS",
        name: "Name",
        cardsInUse: "Badge in use",
        floors: "Floors",
        buttonAdd: "Add tenant",
        dialogTitle: "Add new tenant",
        addSuccess: "Tenant added successfully",
        deleteSuccess: "Tenant deleted successfully",
        buttonDelete: "Delete",
        deleteDialogMessage: "Are you sure you want to delete tenant?",
        validation: {
          nameRequired: "Name is required",
        },
      },
      tenantDetails: {
        tab: "Tenant | SignalOS",
        title: "Tenant",
        tabs: {
          carriers: "Users",
          cards: "Badges",
          profiles: "Categories",
          admins: "Admins",
        },
        carriers: {
          tab: "Users | SignalOS",
          title: "Users",
        },
        cards: {
          tab: "Badges | SignalOS",
          title: "Badges",
        },
        admins: {
          tab: "Admins | SignalOS",
          title: "Admins",
        },
      },
      carriers: {
        title: "Users",
        tab: "Users | SignalOS",
        email: "Email",
        tenant: "Tenant",
        assignTenant: "Assign tenant",
        addCarrier: "Add user",
        name: "Tenant name: ",
        id: "Id: ",
        createdAt: "Created at",
        updatedAt: "Updated at",
        firstName: "First name",
        lastName: "Last name",
        dialogTitle: "Assign tenant to picked users",
        dialogSuccess: "Tenant successfully assigned to users",
        emailRequired: "Email is required",
        firstNameRequired: "First name is required",
        lastNameRequired: "Last name is required",
        addCarrierTitleDialog: "Add user",
        popoverTitle: "Is in use",
        popoverTenantTitle: "Tenant assigned",
        false: "No",
        true: "Yes",
      },
      cards: {
        tab: "Badges | SignalOS",
        title: "Badges",
        number: "Number",
        inuse: "Is in use",
        createdAt: "Created at",
        updatedAt: "Updated at",
        tenant: "Tenant",
        assignTenant: "Assign tenant",
        addCard: "Add Badge",
        numberRequired: "Badge number is required",
        typeRequired: "Type is required",
        type: "Type",
        tenantName: "Name",
        dialogTitle: "Add Badge",
        dialogSuccess: "Tenant successfully added to badges",
      },
      categories: {
        tab: "Categories | SignalOS",
        title: "Categories",
        toastSuccess: "Categories saved successfully",
      },
      admins: {
        tab: "Admins | SignalOS",
        title: "Admins",
      },
      adapters: {
        tab: "Adapters | SignalOS",
        title: "Adapters",
        synchronizeSuccess: "Synchronizing completed",
        adapter: "Adapter",
        status: "Status",
        ping: "Ping",
      },
    },
  },
  pl: {
    translation: {
      common: {
        search: "Szukaj",
        all: "Wszystkie",
        bulkActions: "Wybierz",
        noResultsFound: "Nie znaleziono wyników",
        page: "Strona",
        of: "z",
        success: "Sukces",
        error: "Błąd",
        noData: "Brak danych",
        alreadyInUse: "Aktualnie w użyciu",
        assign: "Przypisz",
        yes: "Tak",
        no: "Nie",
        areYouSure: "Czy na pewno?",
        logout: "Wyloguj",
        aboutUs: "O nas",
        show: "Pokaż",
        actions: "Akcje",
        dragNDrop: "Przeciągnij swój plik tutaj, lub kliknij i wybierz",
        perPage: "Na stronę:",
        availableSoon: "Dostępne wkrótce",
        button: {
          save: "Zapisz",
          cancel: "Anuluj",
          detach: "Odpisz",
          delete: "Usuń",
          upload: "Załaduj",
        },
        profileVariants: {
          assigning: "Przypisywanie",
          removing: "Usuwanie",
          synchronizing: "Synchronizacja",
          scheduled: "Zaplanowany",
          inserting_connection_error: "Błąd podczas wstawiania połączenia",
          widthdrawing_connection_error: "Błąd połaczenia",
          removing_error: "Błąd przy usuwaniu",
          assigning_error: "Błąd przy przypisywaniu",
          failed: "Błąd",
          max_limit_exhausted: "Maksymalna ilośc prób osiągnięta",
          assigned: "Przypisany",
          queued_to_remove: "Zakolejkowany do usunięcia",
          queued_to_assign: "Zakolejkowany do przypisania",
        },
        badgesVariants: {
          assigned: "Przypisany",
          available: "Dostępny",
          blocked: "Zablokowany",
          error: "Błąd",
          other: "Inny",
          max_limit_exhausted: "Maksymalna ilość prób osiągnięta",
          new: "Nowy",
          queued: "Zakolejkowany",
          inserting_connection_error: "Błąd przy wczytywaniu",
          widthdrawing_connection_error: "Błąd przy połączeniu",
        },
      },
      profiles: {
        title: "Profile",
        tab: "Profile | SignalOS",
        assignProfiles: "Przypisz profile",
        detachCheckedProfiles: "Odczep zaznaczone profile",
        thereAreNoProfilesToAssign: "Brak profili do przypisania",
        bulkActions: "Działania Masowe",
        assignSelectedProfiles: "Przypisz wybrane profile",
        profilesUpdated: "Profile zaktualizowane",
        columns: {
          profileId: "Id profilu",
          name: "Nazwa",
          type: "Typ",
          state: "Stan",
          from: "Od",
          to: "Do",
          accessControlId: "Id kontroli dostępu",
          createdAt: "Utworzony",
          updatedAt: "Zaktualizowany",
          isDefault: "Jest domyślny",
          isInUseByCarrier: "Jest używany",
        },
        assignDialog: {
          title: "Przypisz wybrane profile do organizacji",
        },
      },
      badges: {
        title: "Identyfikatory",
        tab: "Identyfikatory | SignalOS",
        assignBadge: "Przypisz identyfikator",
        uploadFile: "Wgraj plik z identyfikatorami",
        createBadge: "Stwórz identyfikator",
        columns: {
          type: "Typ",
          createdAt: "Utworzona",
          updatedAt: "Zaktualizowana",
          isInUse: "Jest w użyciu",
          accessControls: "Kontrola dostępu",
          assignedTo: "Przypisana do",
        },
        createDialog: {
          title: "Stwórz identyfikator",
          name: "Nazwa identyfikatora",
          type: "Typ",
        },
        assignBadgeDialog: {
          title: "Przypisz identyfikator do organizacji",
        },
        deleteDialog: {
          message: "Czy na pewno chcesz usunąć identyfikator: ",
          message2: "Tego nie da się cofnąć",
        },
        detachDialog: {
          message: "Czy na pewno chcesz odczepić identyfikator: ",
        },
        uploadDialog: {
          title: "Wgraj plik z identyfikatorami (xlsx)",
          download: "Ściągnij wzór pliku",
        },
        toast: {
          badgeCreated: "Identyfikator stworzony",
          badgeDeleted: "Identyfikator usunięty",
          badgeAssigned: "Identyfikator przypisany",
          badgeDetached: "Identyfikator odpisany",
          badgeUploaded: "Identyfikatory załadowane",
          alreadyAttached: "You can't detach the badge, which is used by organization",
        },
      },
      organizations: {
        title: "Organizacje",
        tab: "Organizacje | SignalOS",
        columns: {
          name: "Nazwa",
          createdAt: "Utworzona",
          updatedAt: "Uaktualniona",
          manageOrganization: "Zarządzaj organizacją",
          manageBadges: "Zarządzaj identyfikatorami",
          manageProfiles: "Zarządzaj profilami",
        },
        badges: {
          title: "- identyfikatory",
          tab: "Identyfikatory organizacji | SignalOS",
          backToOrg: "Wróć do organizacji",
          doYouWantToDetach: "Czy na pewno chcesz odpiąć identyfikator:",
        },
        profiles: {
          title: "- profile",
          tab: "Profile organizacji | SignalOS",
          backToOrg: "Wróć do organizacji",
          assignProfiles: "Przypisz profile",
          doYouWantToDetach: "Czy na pewno chcesz odpisać profil:",
        },
      },
      tenants: {
        title: "Najemcy",
        tab: "Najemcy | SignalOS",
        name: "Nazwa",
        buttonAdd: "Dodaj najemce",
        addSuccess: "Najemca dodany pomyślnie",
        deleteSuccess: "Najemca usunięty pomyślnie",
        buttonDelete: "Usuń",
        deleteDialogMessage: "Czy na pewno chcesz usunąć najemce?",
        validation: {
          nameRequired: "Nazwa jest wymagana",
        },
      },
    },
  },
};

export const initializeI18n = (lng: string): void => {
  i18n.use(initReactI18next).init({
    resources,
    lng,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
};
