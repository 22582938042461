import { useEffect, useState } from "react";
import type { FC } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { Box, Button, Card, CssBaseline, ThemeProvider, Typography } from "@material-ui/core";
import { initializeI18n } from "./i18n";
import { RTL } from "./components/rtl";
import { useSettings } from "./contexts/settings-context";

import routes from "./routes";
import { createCustomTheme } from "./theme";
import { useAuth0 } from "@auth0/auth0-react";
import store from "./store/ApplicationStore";
import Loader from "./components/atoms/Loader/Loader";

import { useMounted } from "./hooks/use-mounted";
import { organizationApi } from "./api/organization";

import { useDialog } from "./hooks/use-dialog";
import { OrganizationNotFoundDialog } from "./components/molecules/Dialogs/OrganizationNotFoundDialog";

import { ROLES } from "src/types/user";
import { Dialog } from "./components/molecules/Dialog";
import { SITES_MOCK } from "src/mock/sites";

export const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const { isAuthenticated, user, isLoading, error, logout, getAccessTokenSilently } = useAuth0();
  const mounted = useMounted();
  const location = useLocation();
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const navigate = useNavigate();

  const [isPickSiteDialogOpen, handleOpenPickSiteDialog, handleClosePickSiteDialog] = useDialog();

  const getToken = async () => {
    try {
      setIsLoadingToken(true);
      const token = await getAccessTokenSilently();
      const role = returnUserRole(user.email);
      // const role = returnUserRole("instalator@signalos.io");
      store.user.setToken(token);
      store.user.setRole(role);
      getSites();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingToken(false);
    }
  };

  const returnUserRole = (email: string) => {
    return email.toLocaleLowerCase() === "instalatorsignalos@gmail.com".toLocaleLowerCase()
      ? ROLES.INSTALLER
      : ROLES.ADMIN;
  };

  useEffect(() => {
    if (isAuthenticated) {
      store.user.setUserOrganizationId(user["https://platform.signalos.io/active_org_id"]);
      getToken();
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (store.user.role === ROLES.INSTALLER) {
      navigate("/installer");
    } else if (location.pathname.includes("installer")) {
      navigate("/");
    }
  }, [store.user.role]);

  const [isLoadingSites, setIsLoadingSites] = useState<boolean>(false);
  const [errorSite, setErrorSite] = useState<string>(null);
  const [isOpenErrorSiteDialog, handleOpenErrorSiteDialog, handleCloseErrorSiteDialog] =
    useDialog();

  const getSites = async () => {
    try {
      setIsLoadingSites(true);
      if (mounted.current) {
        const sites = await organizationApi.getSites(store.user.userOrganizationId);

        store.site.setSites(sites);
        store.site.setSiteToken(sites[0].token);
        store.site.setPickedSite(sites[0]);
      }
    } catch (err) {
      console.error(err);
      setErrorSite(err.response?.data?.message);
      handleOpenErrorSiteDialog();
    } finally {
      setIsLoadingSites(false);
    }
  };

  useEffect(() => {
    initializeI18n(settings.language);
  }, [settings]);

  const theme = createCustomTheme({
    direction: settings.direction,
    theme: settings.theme,
  });

  useEffect(() => {
    if (JSON.parse(window.localStorage.getItem("sitePicked"))) return;
    handleOpenPickSiteDialog();
  }, []);

  const [activeSite, setActiveSite] = useState(null);
  const handleClickSite = (name: string) => {
    setActiveSite(name);
  };

  const renderContent = () => {
    if (isLoading || isLoadingToken || isLoadingSites) {
      return (
        <Box
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}
        >
          <Loader />
        </Box>
      );
    }
    if (error) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Typography>Oops... {error.message}</Typography> <br />
          <Button
            variant="contained"
            onClick={() => {
              logout();
            }}
          >
            Logout
          </Button>
        </Box>
      );
    } else {
      return content;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        {/* <OrganizationNotFoundDialog
          title="Error"
          variant="error"
          message={`${errorSite}.`}
          open={isOpenErrorSiteDialog}
          onConfirm={handleCloseErrorSiteDialog}
        /> */}
        {renderContent()}
        {user && store.user.role === ROLES.ADMIN && (
          <Dialog
            title="Pick Site"
            open={isPickSiteDialogOpen}
            onCancel={handleClosePickSiteDialog}
            variant="info"
            isConfirmButtonDisabled={!activeSite}
            onConfirm={() => {
              window.localStorage.setItem("sitePicked", JSON.stringify(true));
              handleClosePickSiteDialog();
            }}
          >
            <Box sx={{ mt: 2, gap: 2, display: "flex", flexDirection: "column" }}>
              {SITES_MOCK.map((site) => (
                <Box key={site.name}>
                  <Card
                    variant="outlined"
                    onClick={() => handleClickSite(site.name)}
                    sx={{
                      p: 4,
                      boxShadow: (theme) => {
                        return activeSite === site.name
                          ? `0px 0px 0px 2px ${theme.palette.primary.main}`
                          : `0px 0px 0px 1px ${theme.palette.divider}`;
                      },
                      mb: 2,
                      cursor: "pointer",
                    }}
                  >
                    <Typography textAlign="center">{site.name}</Typography>
                  </Card>
                </Box>
              ))}
            </Box>
          </Dialog>
        )}
      </RTL>
    </ThemeProvider>
  );
};
