import { Suspense, lazy } from "react";
import type { PartialRouteObject } from "react-router";
import { Navigate } from "react-router-dom";
import { Layout } from "./layout/MainLayout";

import { LoadingScreen } from "./components/LoadingScreen";

import { GuestGuard } from "./components/Guards/GuestGuard";
import AuthGuard from "./components/Guards/AuthGuard";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const BadgesPage = Loadable(
  lazy(() =>
    import("./containers/badges").then((module) => ({
      default: module.default,
    }))
  )
);

const Organizations = Loadable(
  lazy(() =>
    import("./containers/organizations").then((module) => ({
      default: module.default,
    }))
  )
);

const OrganizationBadges = Loadable(
  lazy(() =>
    import("./containers/organizations/badges").then((module) => ({
      default: module.default,
    }))
  )
);

const OrganizationProfiles = Loadable(
  lazy(() =>
    import("./containers/organizations/profiles").then((module) => ({
      default: module.default,
    }))
  )
);

const Profiles = Loadable(
  lazy(() =>
    import("./containers/profiles").then((module) => ({
      default: module.ProfilesContainer,
    }))
  )
);
const Profile = Loadable(
  lazy(() =>
    import("./containers/profiles/[profileId]").then((module) => ({
      default: module.ProfileContainer,
    }))
  )
);

const Installer = Loadable(
  lazy(() =>
    import("./containers/installer").then((module) => ({
      default: module.InstallerContainer,
    }))
  )
);

const Carriers = Loadable(
  lazy(() =>
    import("./containers/carriers").then((module) => ({
      default: module.CarriersContainer,
    }))
  )
);
const Tenants = Loadable(
  lazy(() =>
    import("./containers/tenants").then((module) => ({
      default: module.TenantsContainer,
    }))
  )
);
const Tenant = Loadable(
  lazy(() =>
    import("./containers/tenants/[tenantId]").then((module) => ({
      default: module.TenantContainer,
    }))
  )
);
const TenantCarriers = Loadable(
  lazy(() =>
    import("./containers/tenants/TenantCarriers").then((module) => ({
      default: module.TenantCarriersContainer,
    }))
  )
);
const TenantProfiles = Loadable(
  lazy(() =>
    import("./containers/tenants/TenantProfiles").then((module) => ({
      default: module.TenantProfilesContainer,
    }))
  )
);
const TenantCards = Loadable(
  lazy(() =>
    import("./containers/tenants/TenantCards").then((module) => ({
      default: module.TenantCardsContainer,
    }))
  )
);
const TenantAdmins = Loadable(
  lazy(() =>
    import("./containers/tenants/TenantAdmins").then((module) => ({
      default: module.TenantAdminsContainer,
    }))
  )
);

const Cards = Loadable(
  lazy(() =>
    import("./containers/cards").then((module) => ({
      default: module.CardsContainer,
    }))
  )
);
const Adapters = Loadable(
  lazy(() =>
    import("./containers/adapters").then((module) => ({
      default: module.AdaptersContainer,
    }))
  )
);
const Categories = Loadable(
  lazy(() =>
    import("./containers/categories").then((module) => ({
      default: module.CategoriesContainer,
    }))
  )
);

// Not found pages
const NotFound = Loadable(
  lazy(() => import("./containers/NotFound").then((module) => ({ default: module.NotFound })))
);

const routes: PartialRouteObject[] = [
  {
    path: "/",
    element: <Navigate to="/profiles" replace />,
  },
  // {
  //   path: "badges",
  //   element: (
  //     <AuthGuard>
  //       <Layout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "/",
  //       element: <BadgesPage />,
  //     },
  //   ],
  // },

  {
    path: "profiles",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Profiles />,
      },
      {
        path: ":profileId",
        element: <Profile />,
      },
    ],
  },
  {
    path: "tenants",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Tenants />,
      },
      {
        path: ":tenantId",
        element: <Tenant />,
        children: [
          {
            path: "/",
            element: <TenantCarriers />,
          },
          {
            path: "/categories",
            element: <TenantProfiles />,
          },
          {
            path: "/badges",
            element: <TenantCards />,
          },
          {
            path: "/admins",
            element: <TenantAdmins />,
          },
        ],
      },
    ],
  },
  // {
  //   path: "Carriers",
  //   element: (
  //     <AuthGuard>
  //       <Layout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "/",
  //       element: <Carriers />,
  //     },
  //   ],
  // },
  {
    path: "cards",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Cards />,
      },
    ],
  },
  {
    path: "adapters",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Adapters />,
      },
    ],
  },
  {
    path: "categories",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Categories />,
      },
    ],
  },
  {
    path: "installer",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Installer />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
