export const SITES_MOCK = [
  {
    token: 1,
    id: 1,
    name: "Warsaw HUB",
  },
  {
    token: 2,
    id: 2,
    name: "Warsaw UNIT",
  },
];
