import { FC } from "react";
import { Outlet, useNavigate, BrowserRouter, useLocation } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import type { Theme } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { useSettings } from "../../contexts/settings-context";
import { Sidebar } from "../../components/organisms/Sidebar";
import Navbar from "../../components/organisms/Navbar";
import { Footer } from "../../components/molecules/Footer";
import useStore from "src/hooks/useStore";
import { ROLES } from "src/types/user";

const LayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: "100%",
  paddingTop: 64,
}));

const LayoutContent = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

export const Layout: FC = () => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const {
    user: { role },
  } = useStore();
  const { settings, saveSettings } = useSettings();

  const handlePinSidebar = (): void => {
    saveSettings({
      ...settings,
      pinSidebar: !settings.pinSidebar,
    });
  };

  return (
    <LayoutRoot>
      <Navbar />
      {!mdDown && role === ROLES.ADMIN && (
        <Sidebar onPin={handlePinSidebar} pinned={settings.pinSidebar} />
      )}
      <LayoutContent
        sx={{
          ml: {
            md: role === ROLES.ADMIN ? (settings.pinSidebar ? "270px" : "73px") : "",
          },
        }}
      >
        <Outlet />
        <Footer />
      </LayoutContent>
    </LayoutRoot>
  );
};
